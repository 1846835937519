import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {
  PaginationWrap,
  PaginationList,
  PaginationItem,
  PaginationLink,
} from "./style";

const Pagination = ({
  currentPage,
  numberOfPages,
  empresa,
  rootPage,
  className,
  ...rest
}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numberOfPages;
  const previousPage =
    currentPage - 1 === 1
      ? rootPage
      : `/${rootPage}/pagina/` + (currentPage - 1).toString();
  const nextPage = `/${rootPage}/pagina/` + (currentPage + 1).toString();
  console.log(rootPage);
  return (
    <PaginationWrap className={cn(className, "pagination")} {...rest}>
      <PaginationList>
        {isFirst ? (
          <PaginationItem>
            <PaginationLink disabled={true} path={previousPage}>
              Anterior
            </PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem classname="prev">
            <PaginationLink path={previousPage}>Anterior</PaginationLink>
          </PaginationItem>
        )}
        {Array.from({ length: numberOfPages }, (_, i) =>
          currentPage === i + 1 ? (
            <PaginationItem key={`page-number-${i + 1}`}>
              <PaginationLink
                active="true"
                path={`${rootPage}/${i === 0 ? "" : "pagina/" + (i + 1)}`}
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          ) : (
            <PaginationItem key={`page-number-${i + 1}`}>
              <PaginationLink
                path={`${
                  i === 0 ? rootPage : `/${empresa}/pagina/` + (i + 1)
                }`}
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          )
        )}
        {isLast ? (
          <PaginationItem>
            <PaginationLink disabled={true} path={nextPage}>
              Siguiente
            </PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem className="next">
            <PaginationLink path={nextPage}>Siguiente</PaginationLink>
          </PaginationItem>
        )}
      </PaginationList>
    </PaginationWrap>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  rootPage: PropTypes.string,
  className: PropTypes.string,
};

export default Pagination;
