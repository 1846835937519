import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import QRCode from "qrcode";
import Anchor from "@ui/anchor";
import Heading from "@ui/heading";
import {
  TarjetaWrap,
  Foto,
  Datos,
  Separador,
  Codigo,
  TiraConTrama,
  Tira,
  AvisoContainer,
  Aviso,
  Borde,
} from "./style";

const Tarjeta = (content, location) => {
  const { empresa, foto, nombre, apellido, dni } = content.content.node.data;

  const bgImage = convertToBgImage(
    getImage(empresa.document.data.tramado.gatsbyImageData)
  );

  const fondo = empresa.document.data.color_fondo;

  const currentURL =
    "https://tarjetas.orangeretail.pe/" + empresa.document.uid + "/";

  const uid = content.content.node.uid;
  const [src, setSrc] = useState("");

  useEffect(() => {
    QRCode.toDataURL(currentURL + uid).then(setSrc);
  }, [currentURL, uid]);

  const tira = (
    <>
      <Foto>
        <GatsbyImage image={foto.gatsbyImageData} alt={nombre.text} />
      </Foto>
      <Datos fondo={fondo}>
        <Heading as={nombre.text.length > 15 ? "h4" : "h3"}>
          {nombre.text}
        </Heading>
        <Heading as={apellido.text.length > 15 ? "h4" : "h3"}>
          {apellido.text}
        </Heading>
        <Separador />
        <Anchor href={empresa.web?.url}>
          <GatsbyImage
            image={empresa.document.data.logo_claro.gatsbyImageData}
            alt={empresa.document.data.nombre.text}
          />
        </Anchor>
      </Datos>
    </>
  );

  const retira = (
    <>
      <Codigo fondo={fondo}>
        <Anchor href={currentURL + uid}>
          <img src={src} alt={`Código QR ${nombre.text}`} />
        </Anchor>
        <Heading as="h2" mt="60px" mb="60px">
          {dni.text}
        </Heading>
      </Codigo>

      <AvisoContainer>
        <Aviso fondo={fondo}>
          Esta identificación es personal e intransferible, solo podrá ser
          utilizada con fines laborales y será devuelta al término de la
          relación con la empresa.
        </Aviso>
        <Borde />
      </AvisoContainer>
    </>
  );

  if (bgImage) {
    return (
      <TarjetaWrap>
        <TiraConTrama fondo={fondo} {...bgImage}>
          {tira}
        </TiraConTrama>
        <TiraConTrama fondo={fondo} {...bgImage}>
          {retira}
        </TiraConTrama>
      </TarjetaWrap>
    );
  } else {
    return (
      <TarjetaWrap>
        <Tira fondo={fondo}>{tira}</Tira>
        <Tira fondo={fondo}>{retira}</Tira>
      </TarjetaWrap>
    );
  }
};

export default Tarjeta;
