import * as React from "react";
import { graphql } from "gatsby";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import Heading from "@ui/heading";
import Tarjeta from "@components/tarjeta";
import Pagination from "@components/pagination";
import { Container, Row, Col } from "@ui/wrapper";
import { TrabajadoresWrap } from "./style";

const TrabajadoresList = ({ data, pageContext, location }) => {
  const { currentPage, numberOfPages } = pageContext;
  const trabajadores = data.allPrismicTrabajador.edges;
  return (
    <Layout location={location}>
      <Header
        logo={
          data.allPrismicTrabajador.edges[0].node.data.empresa.document.data
            .logo_oscuro.gatsbyImageData
        }
      />
      <TrabajadoresWrap>
        <Container>
          <Row pb="30px" pt="30px">
            <Col>
              <Heading color="primary">Trabajadores</Heading>
            </Col>
          </Row>
          <Row>
            {trabajadores.map((trabajador, i) => (
              <Col md={6} key={i}>
                <Tarjeta
                  location={location.href}
                  content={trabajador}
                  key={`trabajdor-${i}`}
                />
              </Col>
            ))}
          </Row>
          <Row pt="30px" pb="30px">
            <Col>
              <Pagination
                mt="40px"
                empresa={
                  data.allPrismicTrabajador.edges[0].node.data.empresa.document
                    .uid
                }
                rootPage={pageContext.uid}
                currentPage={currentPage}
                numberOfPages={numberOfPages}
              />
            </Col>
          </Row>
        </Container>
      </TrabajadoresWrap>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query TrabajadoresListQuery($skip: Int!, $limit: Int!, $uid: String!) {
    allPrismicTrabajador(
      filter: { data: { empresa: { uid: { eq: $uid } } } }
      limit: $limit
      sort: { fields: data___nombre___text, order: DESC }
      skip: $skip
    ) {
      edges {
        node {
          uid
          data {
            cargo {
              text
            }
            empresa {
              document {
                ... on PrismicEmpresa {
                  uid
                  data {
                    color_fondo
                    logo_claro {
                      gatsbyImageData
                    }
                    logo_oscuro {
                      gatsbyImageData
                    }
                    tramado {
                      gatsbyImageData
                    }
                    nombre {
                      text
                    }
                    primer_plano
                    segundo_plano
                    web {
                      url
                    }
                  }
                }
              }
            }
            nombre {
              text
            }
            apellido {
              text
            }
            dni {
              text
            }
            foto {
              gatsbyImageData(width: 200, height: 260)
            }
          }
        }
      }
    }
  }
`;

export default TrabajadoresList;

/* gatsbyImageData(imgixParams: { monochrome: "#fff" }) */
