import styled, { css, themeGet } from "@styled";
import BackgroundImage from "gatsby-background-image";

export const TarjetaWrap = styled.div`
  text-align: center;
`;

const TiraStyled = css`
  background-color: ${(props) => props.fondo};
  padding: 60px 20px 40px;
  //border-radius: ${themeGet("radii.lg")};
  width: 414px;
  height: 642px;
  margin: 20px;
  h2 {
    margin-bottom: 5px;
    line-height: 1.1;
  }
  h2,
  h3,
  h4 {
    color: #fff;
  }
`;

export const TiraConTrama = styled(BackgroundImage)`
  ${TiraStyled};
`;

export const Tira = styled.div`
  ${TiraStyled};
`;

export const Foto = styled.div`
  img {
    box-shadow: ${themeGet("shadows.default")};
  }
`;

export const Datos = styled.div`
  text-transform: uppercase;
  margin: 0 auto;
  padding: 20px 0;
  background-color: ${(props) => props.fondo};
  box-shadow: ${themeGet("shadows.default")};
  color: #fff;
  p {
    font-size: 12px;
  }
`;

export const Codigo = styled.div`
  background-color: ${(props) => props.fondo};
`;

export const Separador = styled.div`
  margin: 15px auto;
  width: 90%;
  border-top: 2px solid #afffc9;
`;

export const AvisoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Aviso = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  color: #fff;

  background-image: linear-gradient(white, white), linear-gradient(white, white),
    linear-gradient(white, white), linear-gradient(white, white),
    linear-gradient(${(props) => props.fondo}, ${(props) => props.fondo});
  background-repeat: no-repeat;
  background-size: 4px 30%, 20% 4px, 4px 30%, 20% 4px,
    calc(100% - 8px) calc(100% - 8px);
  background-position: left bottom, left bottom, right top, right top, 4px 4px;

  height: 200px;
  //width: 460px;
  //margin: 20px;
  padding: 20px;
`;

export const Borde = styled.div`
  background-image: linear-gradient(red, purple),
    linear-gradient(to right, purple, red),
    linear-gradient(to bottom, purple, red),
    linear-gradient(to left, purple, red), linear-gradient(steelblue, steelblue);
`;
